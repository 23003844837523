<template>
  <div>
    <div class="upload distance">
      <span><a-icon type="upload" class="distance" />{{text}}</span>
      <!--  -->
      <input type="file" :id="'file'+id" name="file" @change="uploadScore($event)" />
    </div>
    <div class="loading" v-if="loading">
      <div class="loading-content">
        <Loading  />
        正在努力上传中，请稍等！
      </div>
    </div>
  </div>
</template>
<script>
import Loading from './Loading'
export default {
  name:'upladfile',
  props:{
    url:String,
    text:String,
    id:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      loading:false
    }
  },
  components:{
    Loading
  },
  methods:{
    //上传成绩
    uploadScore(event) {
      var fl = document.getElementById('file'+this.id);
      var file = event.target.files[0]; //获取文件
      let formdata = new FormData();
      formdata.append("file", file);
      formdata.append("examSpotId", this.$store.state.curSpotId);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data" //之前说的以表单传数据的格式来传递fromdata
        }
      };
      this.loading = true 
      this.$post(this.url, formdata, config).then(() => {
        this.loading = false
        fl.value = ""
        this.$success({
          okText: "确定",
          title: "成功",
          content: "文件上传成功！",
          onOk() {}
        });
        this.$emit('uploadOk')
      }).catch(err=>{
        this.loading = false;
        fl.value = ""
      });
    },
  }
}
</script>
<style scoped>
.upload {
  position: relative;
  width: 120px;
  height: 30px;
  border: solid 1px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.upload span {
  text-align: center;
  width: 100%;
  height: 100%;
  display: block;
}
.upload input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.loading{
  position:fixed;
  left:0;
  top:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.7);
  z-index:99;
}
.loading-content{
  position: absolute;
  top:50%;
  left:50%;
  margin-top:-50px;
  margin-left:-30px;
  color:#2980ff;
  font-size:20px;
}
</style>