<template>
  <div class="import-container">
    <div class="filter">
      <a-row>
        <a-col :xxl="4" :xl="5" :lg="6" :sm="12" :xs="24" class="flex">
          <label>考试日期：</label>
          <a-date-picker class="flex1" :allowClear="true" v-model="filter.cdExamDay" placeholder="考试日期" format="YYYY-MM-DD" />
        </a-col>
        <!-- <a-col :xl="4" :lg="6" :sm="12" :xs="24" class="flex">
          身份证号：<a-input class="flex1" v-model="filter.cdIdCard" placeholder="身份证号"></a-input>
        </a-col>
        <a-col :xl="4" :lg="6" :sm="12" class="flex ">
          是否已强训：
          <a-select v-model="filter.cdHadHotTrain" class="flex1">
            <a-select-option value="">
              所有
            </a-select-option>
            <a-select-option :value="true">
              已强训
            </a-select-option>
            <a-select-option :value="false">
              未强训
            </a-select-option>
          </a-select>
        </a-col> -->
        <a-col :xl="7" :lg="6" :sm="12" class="flex ">
          <a-button type="default" class="clear-btn" @click="clearFilter">清空</a-button>
          <a-button type="primary" class="dis" @click="filter.pageNum=1;getStuList()">查询</a-button>
          <!-- <a-button type="primary" @click="exportStu">导出execl</a-button> -->
          <!-- <a-button class="dis" @click="exportStuTxt" icon="cloud-download">导出TXT</a-button> -->
        </a-col>
      </a-row>
      <div class="import-eg">
        <a-button type="primary" @click="exportStuNoTrain" icon="cloud-download">导出无训练记录（Excel）</a-button>
      </div>
       <div class="import-eg">
         <upload-file style="display:inline-block;background:#9cacff;margin-right:10px;" id="cj" :url="$url.成绩导入" text="导入考试成绩" @uploadOk = "uploadGrade" />
        <span v-if="!showTemplate">上传的excel仅xls格式，请您参照模板上传！</span>
        <!-- <a-button type="link"  v-if="!showTemplate" @click="showTemplate=!showTemplate">查看导入模板</a-button>
        <a-button v-else type="link" @click="showTemplate=false">查看导入学员</a-button>  -->
      </div>
      <div class="import-eg">
        <upload-file style="display:inline-block;background:#ffbb9c;margin-right:10px;" id="yy" :url="$url.导入预约学员" text="预约名单导入" @uploadOk ="uploadSuccess" />
        <span v-if="!showTemplate">上传的excel仅xls格式，为了保证上传不出现错误，请您参照模板上传！</span>
        <a-button type="link"  v-if="!showTemplate" @click="showTemplate=!showTemplate">查看导入模板</a-button>
        <a-button v-else type="link" @click="showTemplate=false">查看导入学员</a-button> 
      </div>
    </div>
    <import-stu-template v-if="showTemplate" />
    <div v-else class="ant-table-wrapper">
      <div
        class="ant-table ant-table-fixed-header ant-table-scroll-position-left ant-table-layout-fixed ant-table-default"
      >
        <div
          class="ant-table-body"
          style="overflowY:scroll;margin-right:-17px"
        >
          <table >
            <thead class="ant-table-thead">
              <tr class="ant-table-row">
                <th>姓名</th>
                <th>身份证</th>
                <th>考试日期</th>
                <th>车型</th>
                <th>驾校</th>
                <th>数量</th>
              </tr>
            </thead>
            <tbody class="ant-table-tbody">
              <tr v-for="(item) in stuList" :key="item.id" :class="{'zero':item.practiceNum==0}">
                <td>{{ item.studentName }}</td>
                <td>{{ item.idCard }}</td>
                <td>{{ item.examDay }}</td>
                <td>{{ item.licName }}</td>
                <td>{{ item.school }}</td>
                <td>{{ item.practiceNum }}</td>
              </tr>
              <tr v-if="!stuList.length">
                <td colspan="6" class="no-data">暂无数据</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div style="padding-top:20px;">
      <a-pagination style="float:right;text-align:center" @change="changPage" :total="total" :pageSize="filter.pageSize" />
    </div>
  </div>
</template>
<script>
import ImportStuTemplate from './components/ImportStuTemplate'
import UploadFile from './components/UploadFile'

export default {
  components:{
    ImportStuTemplate,
    UploadFile
  },
  props:{
    
  },
  data() {
    return {
      showTemplate:false,
      total:0,
      filter:{
        pageNum:1,
        pageSize:this.pageSize,
        cdOutIdCardOnly:false,
        examSpotId:this.$store.state.curSpotId,
        cdHadHotTrain:null,
        cdExamDay:'2021-09-14',
        cdIdCard:''
      },
      stuList:[],
    }
  },
  mounted(){
    this.filter.cdExamDay = this.$moment(new Date()).format("YYYY-MM-DD")
    this.getStuList()
  },
  methods: {
    uploadSuccess(){
      this.showTemplate = false
      this.getStuList()
    },
    uploadGrade(){
      this.$tips("操作成功","考生成绩导入成功！","success")
    },
    exportStu(){
      this.$downExe( this.$url.导出预约学员, this.filter, '预约学员' )
    },
    exportStuNoTrain(){
      this.filter.cdHadHotTrain = false
      this.getStuList()
      this.$downExe( this.$url.导出预约学员, this.filter, '无训练记录学员' )
    },
    exportStuTxt(){
      this.filter.cdOutIdCardOnly = true
      this.$downTxt( this.$url.导出预约学员, this.filter, '预约学员Txt' )
    },
    changPage(page){
      this.filter.pageNum = page;
      this.getStuList()
    },
    clearFilter(){
      this.filter = {
        pageNum:1,
        pageSize:this.pageSize,
        cdOutIdCardOnly:false,
        examSpotId:this.$store.state.curSpotId,
        cdHadHotTrain:'',
        cdExamDay:this.$moment(new Date()).format("YYYY-MM-DD"),
        cdIdCard:''
      }
      this.getStuList()
    },
    getStuList(){
      if(!this.filter.examSpotId)return
      this.filter.cdOutIdCardOnly = false
      if(this.filter.cdExamDay)this.filter.cdExamDay=this.$moment(this.filter.cdExamDay).format('YYYY-MM-DD')
      this.$post(this.$url.预约学员列表,this.filter).then(data => {
        this.stuList = data.data.list
        this.total = data.data.total
      }).catch(() => {
          
      });
    },
  },
}
</script>
<style scoped>
table .ant-table-tbody tr.zero  td{
  background:#ffeded;
}
.import-eg{
  padding-top:15px;
  border-top:1px solid rgba(0,0,0,0.05);
  margin-top:10px;
}
.flex{
  margin:5px 0;
  padding:0 5px;
}
.act-con{
  padding:5px 0 0;
}
.act-con button{
  margin:5px 3px;
}
.filter{
  height:auto;
  margin-bottom:15px;
  line-height:30px;
}
.type-name{
  height:30px;
  width:70px;
  top:0;
  margin-right:0;
}
.clear-btn{
  margin-right:10px;
}
.ant-table-tbody > tr:nth-child(2n) > td{
  background:#f3f8ff;
}
.import-container{
  margin-bottom:20px;
  overflow-y:scroll;
  overflow-x:hidden;
}
</style>