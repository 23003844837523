<template>
    <div class="spinner">
        <div class="spinner-container container1">
            <div class="circle1"></div>
            <div class="circle2"></div>
            <div class="circle3"></div>
            <div class="circle4"></div>
        </div>
        <div class="spinner-container container2">
            <div class="circle1"></div>
            <div class="circle2"></div>
            <div class="circle3"></div>
            <div class="circle4"></div>
        </div>
    </div>
</template>
<script>
    export default{
        name:'Loading',
        data(){
            return{

            }
        },
        methods:{

        }
    }
</script>  
<style scoped>
.spinner {
  margin:30px auto;
  width:60px;
  height:60px;
  position:relative;
}
 
.container1 > div, .container2 > div {
  width:12px;
  height:12px;
  background-color:#2980ff;
 
  border-radius:100%;
  position:absolute;
  -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
  animation: bouncedelay 1.2s infinite ease-in-out;
  -webkit-animation-fill-mode:both;
  animation-fill-mode:both;
}
 
.spinner .spinner-container {
  position:absolute;
  width:100%;
  height:100%;
}
 
.container2 {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
 
 
.circle1 {top:0;left:0; }
.circle2 {top:0;right:0; }
.circle3 {right:0;bottom:0; }
.circle4 {left:0;bottom:0; }
 
.container2 .circle1 {
  -webkit-animation-delay:-1.1s;
  animation-delay:-1.1s;
}

 
.container1 .circle2 {
  -webkit-animation-delay:-0.9s;
  animation-delay:-0.9s;
}
 
.container2 .circle2 {
  -webkit-animation-delay:-0.8s;
  animation-delay:-0.8s;
}
 
 
.container1 .circle3 {
  -webkit-animation-delay:-0.6s;
  animation-delay:-0.6s;
}
 
.container2 .circle3 {
  -webkit-animation-delay:-0.5s;
  animation-delay:-0.5s;
}
 
.container1 .circle4 {
  -webkit-animation-delay:-0.3s;
  animation-delay:-0.3s;
}
 
.container2 .circle4 {
  -webkit-animation-delay:-0.2s;
  animation-delay:-0.2s;
}

@-webkit-keyframes bouncedelay {
  0%,80%,100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}
 
@keyframes bouncedelay {
  0%,80%,100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
</style>